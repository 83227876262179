/* Pricing.css */

.pricing-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
}

.pricing-item {
  background-color: #f8fafc;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.pricing-item h3 {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  color: #1e40af;
}

.pricing-item p {
  margin: 8px 0;
  font-size: 14px;
  color: #4b5563;
}

.pricing-price {
  font-weight: bold;
  font-size: 24px;
  color: #1e293b;
  margin: 16px 0;
}

.generate-button {
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #1d4ed8;
}

.addons-title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
}

.addons-list {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.addons-list li {
  margin: 10px 0;
  font-size: 14px;
  color: #4b5563;
}

.transaction-fees {
  margin-top: 40px;
  font-size: 14px;
  color: #64748b;
  text-align: center;
}

.payment-method-dropdown {
  margin-top: 10px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
}
